import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-business-card',
  templateUrl: './business-card.component.html',
  styleUrls: ['./business-card.component.scss']
})
export class BusinessCardComponent implements OnInit {
  id: string
  data: any;
  constructor(private route: ActivatedRoute,private http: HttpClient) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getData();
  }
  getData(){
    this.http.get('../../assets/business-card.json').subscribe(res => {
      this.data = res;
    });
  }
  generate(){
    var  vcard = "BEGIN:VCARD\nVERSION:3.0\nFN:" + this.data.name + "\nTEL:" + this.data.notelp_display + "\nEMAIL:" + this.data.email[1] + "\nURL:" + this.data.website  + "\nEND:VCARD"
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);
    window.open(url);
  }

}
