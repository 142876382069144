

<div class="experience-card"  id="project">
    <div [ngStyle]="{background: experience.color}" class="experience-banner">
      <div class="experience-blurred_div"></div>
      <div class="experience-div-company">
        <!-- <h5 class="experience-text-company">{{experience.company}}</h5> -->
        <img crossOrigin="anonymous" class="experience-roundedimg" [src]="experience.companylogo" >
      </div>
  
  <!--    <p>{{colors.myDominantColor}}</p>-->
    </div>
    <div class="experience-text-details">
      <h5 class="experience-text-role">{{experience.role}}</h5>
      <p class="subTitle experience-text-desc">{{experience.desc}}</p>
      <ul>
        <li class="subTitle" *ngFor="let lis of experience.descBullets">{{lis}}</li>
      </ul>
    </div>
  </div>
  
  
  