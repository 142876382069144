
<div class="greet-main" id="greeting">
  <div class="greeting-main">
    <div class="greeting-text-div">
      <div>
        <h1 class="greeting-text">
          Hi, I'm Daffa
          <span class="wave-emoji">👋</span>
        </h1>
        <p class="greeting-text-p subTitle">A computer science graduate with experience in several projects. Interested and have knowledge in some framework related to web development and data science. Capable to manage an Independent project and also collaborate in a productive team.</p>
            <app-social-media></app-social-media>
        <div class="button-greeting-div">
<!--        button-->
          <div>
            <a class="main-button" href="#contact">Contact me</a>
          </div>
          <div>
            <a class="main-button" href="https://docs.google.com/document/d/1XKtSHmE5VLkS_3BtufYMPy9nnyaZhPUY/">See my resume</a>
          </div>
<!--          <Button text="Contact me" href="#contact" />-->
<!--          <Button text="See my resume" newTab={true} href={greeting.resumeLink} />-->
        </div>
      </div>
    </div>
    <div class="greeting-image-div">
      <img src="../../assets/images/manOnTable.svg" alt="">
    </div>
  </div>
</div>
