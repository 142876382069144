import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  public workExperiences = {
    viewExperiences: true,
    experience: [
      {
        role: "Agrowisata Amanah Payroll Management",
        color: "#3781c2",
        companylogo: "../../../assets/images/payroll.png",
        desc: "Human resource management aplication for Agrowisata Amanah with credit management",
      },
      {
        role: "Agrowisata Amanah Membership Application",
        color: "#3781c2",
        companylogo: "../../../assets/images/loyalty.png",
        desc: "Loyalty program for Agrowisata Amanah customers",
      },
      {
        role: "Libreria Di Ricette",
        color: "#3781c2",
        companylogo: "../../../assets/images/librea.jpg",
        desc: "Food recipe sharing application integrated with recipe ingredients vendors",
      },
      {
        role: "Shop Flobamora",
        color: "#3781c2",
        companylogo: "../../../assets/images/umkm.png",
        desc: "Small and Medium Enterprise (SME) catalogue for Kilau Permata FLOBAMORA 2021 event in Flores, Sumba, Timor and Alor area",
      },
      {
        role: "Car Purchase Interest Classification",
        color: "#3781c2",
        companylogo: "../../../assets/images/clustering.png",
        desc: "Car purchase interest level for set of people based on many parameters using Random Forest Classifier",
      },
      {
        role: "Jakarta's Air Temperature Prediction",
        color: "#3781c2",
        companylogo: "../../../assets/images/lstm.png",
        desc: "Jakarta's air temperature using two machine learning method, Long Short Term Memory and Facebook Prophet",
      },
      
      // descBullets: [
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      // ]
    ]
  };
  constructor() { }

  ngOnInit(): void {
  }

}
