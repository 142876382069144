import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  id: string;
  data: any;
  datas: any;
  isview: boolean = false;
  constructor(private route: ActivatedRoute,private http: HttpClient) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getData();
  }
  getData(){
    this.http.get('../../assets/tag.json').subscribe(res => {
      this.datas = res;
      this.datas.forEach(element => {
        if(element.id == this.id){
          console.log(element.nama_barang);
          this.data = element;
          this.isview = true;
        }
      });
    });
  }

}
