import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public contactInfo = {
    title: "Contact Me ☎️",
    subtitle: "Contact me ",
    number: "",
    email_address: "mail@daffaharis.com"
  };
  constructor() { }

  ngOnInit(): void {
  }

}
