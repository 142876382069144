import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  timeline = [
    // {
    //   heading: "Infosys",
    //   duration: "Present",
    //   subtitle: "",
    //   content: "Upcoming Full stack develover at Infosys!",
    //   className1: "mar-left",
    //   className2: "prt_about_learnbox_right"
    // },
    {
      heading: "Bachelor in Computer Science",
      duration: "2022",
      subtitle: "Telkom University",
      content: "Graduated with 3.84/4.00 GP",
      content2: true,
      className1: "mar-right",
      className2: "prt_about_learnbox_left"
    },
    {
      heading: "Natural Science Graduate",
      duration: "2018",
      subtitle: "SMA Negeri 6 Surakarta",
      content: "Graduated from Natural Science program.",
      className1: "mar-left",
      className2: "prt_about_learnbox_right"
    }
    ];

  constructor() { }

  ngOnInit(): void {
  }

}
