<link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.0.8/css/all.css" integrity="sha384-3AB7yXWz4OeoZcPbieVW64vVXEwADiYyAEhwilzWsLw+9FgqpyjjStpPnpBO8o8S" crossorigin="anonymous">
<div class="profile-card-4 text-center" style="background-color: #35948B;" *ngIf="isview == true">
	<img src="../../assets/images/46914307.jpg" class="img img-responsive">
	<div class="profile-content" style="background-color: #35948B;">
		<div class="profile-name d-flex flex-row">
			<!-- <app-social-media></app-social-media> -->
		</div>
		<div class="row text-left">
			<div class="col-12" style="color: #fff; border-bottom: 1px solid;">
				<p style="text-align:center;">Apabila menemukan {{data?.nama_barang}} ini, harap menghubungi nomor dibawah ini, terimakasih!</p>
			</div>
			<div class="col-12" style="color: #fff;">
				<p style="text-align:center;"><i>If you found this {{data?.nama_barang}}, please contact the number below, thank you!</i></p>
			</div>
			<div class="col-12 d-flex justify-content-center mb-4">
				<div class="row mt-4">
					<div class="col-12 text-center">
						<h4 style="font-weight: bold; color: #fff;">{{data?.name}}</h4>
					</div>
					<div class="col-12 text-center">
						<p style="color: #fff;">{{data?.location}}</p>
					</div>
				</div>
			</div>
			<div class="col-2 my-3 align-self-center mt-4">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"  class="icon"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M50.7 58.5L0 160H208V32H93.7C75.5 32 58.9 42.3 50.7 58.5zM240 160H448L397.3 58.5C389.1 42.3 372.5 32 354.3 32H240V160zm208 32H0V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192z"/></svg>	
			</div>
			<div class="col-10 my-3 align-self-center">
				<p class="teks">{{data?.nama_barang}}</p>
			</div>
			<div class="col-2 my-3 align-self-center mt-4">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg>
			</div>
			<div class="col-10 my-3 align-self-center">
				<a href="tel:{{data?.notelp}}" class="teks">{{data?.notelp_display}}</a>
			</div>
			
			<div class="col-12 my-4 align-self-center">
				
			</div>
		</div>
	</div>
</div>