<!--<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">-->
<div class="social-media-div">
  <a href="https://github.com/Mohammaddaffaharis" class="icon-button github" target="_blank">
    <i class="fab fa-github"></i>
    <span></span>
  </a>
    <a href="https://www.linkedin.com/in/mohammad-daffa-haris/" class="icon-button linkedin" target="_blank">
      <i class="fab fa-linkedin"></i>
      <span></span>
    </a>
    <a href="mailto:mohammaddaffaharis@gmail.com" class="icon-button google" target="_blank">
      <i class="fab fa-google"></i>
      <span></span>
    </a>
    <a href="https://www.instagram.com/daffa_hrs/" class="icon-button instagram" target="_blank">
      <i class="fab fa-instagram"></i>
      <span></span>
    </a>
</div>
