
<script
  src="https://unpkg.com/github-calendar@latest/dist/github-calendar.min.js">
</script>
<link
  rel="stylesheet"
  href="https://unpkg.com/github-calendar@latest/dist/github-calendar-responsive.css"
/>
<div class="calendar">
  Loading the data just for you.
</div>
