
<div *ngIf="workExperiences.viewExperiences">
    <div id="experience">
        <div class="experience-container" id="workExperience">
          <div>
            <h1 class="experience-heading">Projects</h1>
            <div class="experience-cards-div">
              <app-project-card  *ngFor="let card of workExperiences.experience" [experience]="card"></app-project-card>
            </div>
          </div>
        </div>
    </div>
  </div>
  
  